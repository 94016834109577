import { useQuery } from '@apollo/client';
import {
  normalizeSlices,
  SliceContainer,
  defaultSlicesMapper,
} from '@scorenco/components';
import { ssrApolloService, Types, useTranslation } from '@scorenco/core';
import { NextPageWithLayout } from '@scorenco/next';
import Error from 'next/error';

import { DebugMode, DebugModeStrapiLink, StrapiPageSeo } from '../components';
import { WebLayout } from '../layouts';
import { RouteKey } from '../routes';
import { isChVersion } from '../utils';
import { redirectToV1 } from '../utils/redirectToV1';

import { GetHomePageDocument } from './_internal/Home.query.generated';
import { GetChHomePageDocument } from './_internal/HomeCh.query.generated';
import { HomeHero } from './_internal/HomeHero';

const Home: NextPageWithLayout<{ preview?: boolean }> = ({ preview }) => {
  const { locale } = useTranslation();

  const homePageQuery = useQuery(GetHomePageDocument, {
    variables: {
      publicationState: Types.PublicationState.Live,
      locale: locale.split('-')[0],
    },
    context: {
      clientName: 'strapi',
    },
    skip: isChVersion(),
  });

  const chHomePageQuery = useQuery(GetChHomePageDocument, {
    variables: {
      publicationState: Types.PublicationState.Live,
      locale: locale.split('-')[0],
    },
    context: {
      clientName: 'strapi',
    },
    skip: !isChVersion(),
  });

  const homePage =
    homePageQuery.data?.pagesHome?.data ||
    chHomePageQuery.data?.pagesChHome?.data;

  if (!homePage && !homePageQuery.loading && !chHomePageQuery.loading) {
    return <Error statusCode={404} />;
  }

  const title = homePage?.attributes?.title;
  const subtitle = homePage?.attributes?.subtitle as string;
  const images = (homePage as Types.PagesHomeEntity)?.attributes?.images;
  const ctaLabel = homePage?.attributes?.ctaLabel as string;
  const seo = homePage?.attributes?.seo;
  const slices = normalizeSlices(homePage?.attributes?.body);

  return (
    <div>
      {seo && <StrapiPageSeo seo={seo} url={{ routeKey: RouteKey.Home }} />}

      {title && (
        <HomeHero
          title={title}
          images={images}
          subtitle={subtitle}
          ctaLabel={ctaLabel}
        />
      )}

      <SliceContainer slices={slices} mapper={defaultSlicesMapper} />

      <DebugMode preview={preview}>
        <DebugModeStrapiLink
          type={isChVersion() ? 'pages-ch-home' : 'pages-home'}
        />
      </DebugMode>
    </div>
  );
};

export const getServerSideProps = WebLayout.buildGetServerSideProps({}, [
  async (options, acc: { props: any }) => {
    options.res.setHeader(
      'Cache-Control',
      `public, s-maxage=${30 * 24 * 60 * 60}, max-age=${15 * 60}`
    );

    const { apolloClient, addApolloState } = ssrApolloService();

    if (options.query['no-ssr'] === undefined) {
      const getHomeQuery = await apolloClient.query({
        query: isChVersion() ? GetChHomePageDocument : GetHomePageDocument,
        context: {
          clientName: 'strapi',
        },
        variables: {
          publicationState: Types.PublicationState.Live,
          locale: options.locale?.split('-')[0] || 'fr',
        },
      });

      if (!getHomeQuery.data) {
        return redirectToV1({ req: options.req, res: options.res });
      }
    }

    return addApolloState({
      ...acc,
      props: { ...acc.props, preview: options?.preview ? true : null },
    });
  },
]);

Home.getLayout = WebLayout.buildGetLayout({ strapiMenu: true });

export default Home;
